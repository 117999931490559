import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Launch from '@mui/icons-material/Launch';
import type { RaRecord } from 'react-admin';

interface MenuProps {
  position: string;
  record: RaRecord;
  setAnchorEl: (val: null | HTMLElement) => void;
}

export const ClickPreviewPositionMenuItem = ({ position, record, setAnchorEl }: MenuProps) => {
  const capitalizedPositionString = position.charAt(0).toUpperCase() + position.slice(1);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <a
      href={`${process.env.NEXT_PUBLIC_MDLINX_BASE_URL}/api/r/clicksPreview?lfcId=${process.env.CLICKS_PREVIEW_FMA_ID}&preview_click=${record?.id}&position=${position}`}
      target="_blank"
      rel="noreferrer"
    >
      <MenuItem onClick={e => handleClick(e)}>
        <ListItemIcon>
          <Launch />
        </ListItemIcon>
        <ListItemText>{capitalizedPositionString}</ListItemText>
      </MenuItem>
    </a>
  );
};
