import { extractNpis } from './parse-target-list';

export const npiNotification = async (file: File, notify: any) => {
  const npis: Array<number> = await extractNpis(file);

  if (npis?.length) {
    notify(`${npis.length.toLocaleString()} NPI numbers found`);
  } else {
    notify(`No NPI numbers found`, { type: 'warning' });
  }
};
