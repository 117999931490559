import { Button, useNotify, useRecordContext } from 'react-admin';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useCallback } from 'react';

interface CopyToClipboardFieldProps {
  source: string;
}

export const CopyToClipboardField = ({ source }: CopyToClipboardFieldProps) => {
  const notify = useNotify();
  const record = useRecordContext();

  const handleCopy = useCallback(() => {
    const value = record[source];
    if (!value) return;

    // Use Clipboard API if available
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          notify('Copied to clipboard', { type: 'info' });
        })
        .catch(() => {
          notify('Failed to copy', { type: 'warning' });
        });
    } else {
      // Fallback to using a temporary text area, for local development over http
      const textArea = document.createElement('textarea');
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        notify('Copied to clipboard', { type: 'info' });
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        notify('Failed to copy', { type: 'warning' });
      }
      document.body.removeChild(textArea);
    }
  }, [record, source, notify]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
      {record[source]}
      <Button onClick={handleCopy}>
        <ContentCopyIcon startOffset={0} />
      </Button>
    </div>
  );
};
