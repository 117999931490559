import React from 'react';
import { AppBar } from 'react-admin';
import Image from 'next/image';
import CSAdminLogo from '../../../../public/csadmin-logo.png';

export const CSAdminAppBar = () => {
  return (
    <AppBar>
      <Image src={CSAdminLogo} alt="CSAdmin logo" width={200} height={50} priority={true} />
      <span style={{ flex: 8 }} />
    </AppBar>
  );
};
