import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useGetList } from 'react-admin';
import { Label } from 'src/generated/client';

type LabelSelectionModalProps = {
  open: boolean;
  selectedLabelId: string;
  onClose: () => void;
  onLabelSelect: (labelId: string) => void;
  onConfirm: () => void;
  confirmDisabled: boolean;
  title: string;
};

export const LabelSelectionModal: React.FC<LabelSelectionModalProps> = ({
  open,
  selectedLabelId,
  onClose,
  onLabelSelect,
  onConfirm,
  confirmDisabled,
  title,
}) => {
  const { data: labels, isLoading: loadingLabels } = useGetList<Label>('labels', {
    filter: { isArchived: false },
    sort: { field: 'name', order: 'ASC' },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 250 }}>
        {loadingLabels ? (
          <CircularProgress />
        ) : (
          <Select
            fullWidth
            value={selectedLabelId}
            onChange={e => onLabelSelect(e.target.value)}
            disabled={loadingLabels}
          >
            {labels?.map(label => (
              <MenuItem key={label.id} value={label.id}>
                {label.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" disabled={confirmDisabled}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};
