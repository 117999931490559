import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  TargetListsPlacementLineItemIdsListDocument,
  TargetListsPlacementLineItemIdsListQuery,
  TargetListsPlacementLineItemIdsListQueryVariables,
} from 'src/generated/client';

export const targetListsFilterByLineItemId = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  includes: string,
) => {
  const queryResult = await apolloClient.query<
    TargetListsPlacementLineItemIdsListQuery,
    TargetListsPlacementLineItemIdsListQueryVariables
  >({
    query: TargetListsPlacementLineItemIdsListDocument,
    variables: {
      includes,
    },
  });
  const targetListsWithLineItemIdList =
    queryResult.data.targetLists?.nodes?.filter(node => node.placementLineItemIdsList?.length) ?? [];

  return {
    data: targetListsWithLineItemIdList,
    total: targetListsWithLineItemIdList.length,
  };
};
