import {
  Create,
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  SimpleForm,
  TextField,
  TextInput,
  FilterButton,
  FilterForm,
  BooleanInput,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  useListContext,
} from 'react-admin';

import { FormHeader } from './forms/form-components';
import { includesInsensitive } from './forms/form-utils';
import { ESTDateField } from './custom-ra-fields/est-date-field';
import { CopyToClipboardField } from './custom-ra-fields/copy-to-clipboard-field';
import { ArchiveToggleButton } from './exclusion-labels/archive-toggle-button';
import { useUser } from '@clerk/nextjs';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Label } from 'src/generated/client';

const ExclusionLabelActions = () => {
  const location = useLocation();
  const showArchived = location.pathname.includes('showArchive'); // Adds the archived filter for those in the know;)
  const { setFilters, filterValues } = useListContext();

  useEffect(() => {
    // Set 'isArchived' to false when navigating away from the page
    if (!showArchived) {
      setFilters({ ...filterValues, isArchived: false }, null);
    }
  }, [showArchived]);

  const exclusionLabelFilters = [
    <TextInput label="Label Name" source="name" {...includesInsensitive} alwaysOn />,
    <TextInput label="Label Description" source="description" {...includesInsensitive} alwaysOn />,
  ];

  if (showArchived) {
    exclusionLabelFilters.push(<BooleanInput label="Archived" source="isArchived" alwaysOn />);
  }

  return (
    <div style={{ display: 'flex', flexGrow: 1, gap: 7 }}>
      <FilterForm filters={exclusionLabelFilters} style={{ width: '100%', display: 'flex', alignItems: 'center' }} />
      <FilterButton filters={exclusionLabelFilters} style={{ flexShrink: 0, display: 'flex' }} />
      <CreateButton label="Create New Label" style={{ flexShrink: 0 }} />
    </div>
  );
};

type LabelRecord = {
  id: Label['id'];
  name: Label['name'];
  description: Label['description'];
  isArchived: Label['isArchived'];
  createdAt: Label['createdAt'];
  updatedAt: Label['updatedAt'];
  updatedBy: Label['updatedBy'];
  __typename: Label['__typename'];
};

export const ExclusionLabelsList = () => (
  <List
    actions={<ExclusionLabelActions />}
    perPage={25}
    filter={{ is_active: true }}
    filterDefaultValues={{ isArchived: false }}
  >
    <Datagrid bulkActionButtons={false}>
      <CopyToClipboardField source="name" />
      <TextField source="description" />
      <ESTDateField source="createdAt" label="Created At" showTime />
      <TextField source="updatedBy" label="Updated By" />
      <FunctionField
        label="Associated Campaigns"
        render={(record: LabelRecord) => (
          <ReferenceManyField
            reference="campaignLabels"
            target="labelId"
            filter={{ labelId: record.id }}
            sort={{ field: 'labelId', order: 'DESC' }}
          >
            <SingleFieldList resource="campaigns" linkType="show">
              <ChipField source="placementsCampaignId" />
            </SingleFieldList>
          </ReferenceManyField>
        )}
      />
      <FunctionField
        label="Associated Line Items"
        render={(record: LabelRecord) => (
          <ReferenceManyField
            reference="lineItemLabels"
            target="labelId"
            filter={{ labelId: record.id }}
            sort={{ field: 'labelId', order: 'DESC' }}
          >
            <SingleFieldList resource="line_items" linkType="show">
              <ChipField source="placementsLineItemId" />
            </SingleFieldList>
          </ReferenceManyField>
        )}
      />
      <ArchiveToggleButton />
    </Datagrid>
  </List>
);

export const ExclusionLabelCreate = () => {
  const { user } = useUser();
  const fullName = user?.fullName;

  return (
    <Create>
      <SimpleForm defaultValues={{ updatedBy: fullName }}>
        <FormHeader>Create Exclusion Label</FormHeader>
        <TextInput source="name" helperText="This name will be used as the targeting key in Google Ads Manager (GAM)" />
        <TextInput multiline fullWidth source="description" />
      </SimpleForm>
    </Create>
  );
};
