import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { Button, useCreate, useGetList, useNotify, useRecordContext } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { CampaignLabel, GetLabelsForCampaignDocument, Label } from 'src/generated/client';
import { LabelSelectionModal } from './label-selection-modal';

export const AddCampaignLabelButton = () => {
  const notify = useNotify();
  const client = useApolloClient();
  const record = useRecordContext();

  const [open, setOpen] = useState(false);
  const [selectedLabelId, setSelectedLabelId] = useState<Label['id']>('');

  const [create, { isLoading }] = useCreate<CampaignLabel>();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLabelSelect = (labelId: string) => setSelectedLabelId(labelId);
  const refetchLabels = () => client.refetchQueries({ include: [GetLabelsForCampaignDocument] });

  const handleConfirm = () => {
    create(
      'campaignLabels',
      { data: { placementsCampaignId: record.id.toString(), labelId: selectedLabelId } },
      {
        onSuccess: () => {
          notify('Label assigned successfully');
          handleClose();
          refetchLabels();
        },
        onError: error => notify(`Error: ${error}`, { type: 'warning' }),
      },
    );
  };

  return (
    <>
      <Button
        label="Add Campaign Exclusion Label"
        onClick={handleOpen}
        disabled={!record}
        color="primary"
        startIcon={<AddIcon />}
      />
      <LabelSelectionModal
        open={open}
        selectedLabelId={selectedLabelId}
        onClose={handleClose}
        onLabelSelect={handleLabelSelect}
        onConfirm={handleConfirm}
        confirmDisabled={isLoading || !selectedLabelId}
        title="Add Campaign Exclusion Label"
      />
    </>
  );
};
