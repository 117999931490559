import { Identifier, useNotify, useRedirect, useDataProvider } from 'react-admin';
import { useMutation } from 'react-query';
import Button from '@mui/material/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useRecordContext, useResourceContext } from 'react-admin';

export const ToolbarDuplicateButton = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const resource = useResourceContext();

  const { mutate, isLoading } = useMutation(() => dataProvider.duplicate({ resource, id: record?.id }), {
    onSuccess: ({ id }: { id: Identifier }) => {
      redirect('edit', resource, id);
      notify('Duplicate successfully created');
    },
    onError: (error: Error) => {
      notify(`Duplication error: ${error.message}`, { type: 'warning' });
    },
  });

  if (!record || record.id == null) {
    return null;
  }

  return (
    <Button
      color="secondary"
      variant="outlined"
      startIcon={<FileCopyIcon />}
      onClick={() => mutate()}
      disabled={isLoading}
    >
      Duplicate
    </Button>
  );
};
