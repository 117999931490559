import { SimpleForm, Loading, useGetOne } from 'react-admin';
import {
  CampaignLineItemsSelectInput,
  CurrentLineItemDateInputGroup,
  FormDivider,
  FormHeader,
  FormSectionHeader,
  LineItemTargetListInput,
} from './form-components';
import { CreativesFormProps } from './fma-creatives';
import { CreativeToolbar } from './shared';
import { useRecordContext } from 'react-admin';

export const BannerTargetingsForm = ({ type, ...rest }: CreativesFormProps) => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetOne('line_items', { id: record.placementsLineItemId });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return null;

  const lineItem = data;

  const defaultValues = {
    ...rest.defaultValues,
    startsAt: lineItem.startDate,
    endsAt: lineItem.endDate,
  };

  return (
    <SimpleForm
      {...rest}
      defaultValues={defaultValues}
      toolbar={<CreativeToolbar />}
      mode="onBlur"
      reValidateMode="onBlur"
    >
      <FormHeader>{`${type} Banner Targeting`}</FormHeader>

      <CampaignLineItemsSelectInput campaignId={lineItem.campaign.id} />

      <FormSectionHeader>Banner Targeting Details</FormSectionHeader>

      <CurrentLineItemDateInputGroup />
      <FormDivider />

      <FormSectionHeader>Targeting Details</FormSectionHeader>

      <LineItemTargetListInput lineItemId={record.placementsLineItemId} />
    </SimpleForm>
  );
};
