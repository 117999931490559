import { useClerk } from '@clerk/nextjs';
import axios from 'axios';

const NEXT_PUBLIC_CSAPI_BASE_URL = process.env.NEXT_PUBLIC_CSAPI_BASE_URL;

export const useTransformWithCampaignId = () => {
  const { session } = useClerk();

  const transform = async (data: any) => {
    try {
      const authToken = await session?.getToken();

      const response = await axios.get(`${NEXT_PUBLIC_CSAPI_BASE_URL}/api/placements/line_items/${data.placementsLineItemId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const campaignId = response.data.data.campaign.id;

      return {
        ...data,
        placementsCampaignId: campaignId,
      };
    } catch (error) {
      console.error('Error fetching campaign ID:', error);
      throw new Error('Error fetching campaign ID');
    }
  };

  return transform;
};
