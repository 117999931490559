import { useRedirect } from 'react-admin';
import { Toolbar, SaveButton, DeleteButton, AutocompleteArrayInput, useGetList } from 'react-admin';
import { ToolbarDuplicateButton } from '../buttons/toolbar-duplicate-button';
import { useFormState } from 'react-hook-form';
import { useRecordContext } from 'react-admin';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';

export const CreativeToolbar = () => {
  const redirect = useRedirect();
  const { isValid, isDirty } = useFormState();
  const disableSave = !isDirty && !isValid;
  const record = useRecordContext();

  const onSuccess = () => {
    redirect('show', '/line_items', record?.placementsLineItemId);
  };

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton disabled={disableSave} />
      <ToolbarDuplicateButton />
      <DeleteButton mutationMode="pessimistic" mutationOptions={{ onSuccess }} />
    </Toolbar>
  );
};

interface SelectInputChoice {
  id: string;
  name: string;
}

type AutocompleteInputProps = {
  resource: string;
  source: string;
  validate?: (value: any, allValues: any) => string | undefined;
};

export const AutocompleteInput = ({resource, source, validate}: AutocompleteInputProps) => {
  const [choices, setChoices] = useState<SelectInputChoice[]>([]);
  const label = resource.charAt(0).toUpperCase() + resource.slice(1)

  const result = useGetList(resource, {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  });

  useEffect(() => {
    if (result.isLoading) return;
    setChoices(result.data as SelectInputChoice[]);
  }, [result]);

  return (
    <Box display="flex">
      <Box>
        <AutocompleteArrayInput
          label={label}
          source={source}
          choices={choices}
          sx={{ minWidth: 250 }}
          validate={validate ?? undefined}
        />
      </Box>
    </Box>
  );
};