import { useUser } from '@clerk/nextjs';
import { useMemo, useState } from 'react';
import { Button, Confirm, useNotify, useRecordContext, useRefresh, useUpdate } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuid } from 'uuid';

export const ArchiveToggleButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { user } = useUser();
  const fullName = user?.fullName;

  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const action = record.isArchived ? 'Restore' : 'Delete';
  const updatedName = useMemo(
    () => (record.isArchived ? restoreName(record.name) : archiveName(record.name)),
    [record.isArchived, record.name],
  );

  const [update, { isLoading }] = useUpdate(
    'archiveLabel',
    {
      id: record.id,
      data: { id: record.id, isArchived: !record.isArchived, name: updatedName, updatedBy: fullName },
    },
    {
      onSuccess: () => {
        notify(`Label ${action.toLowerCase()}d successfully`);
        refresh();
      },
      onError: error => {
        notify(`Error: ${error}`, { type: 'warning' });
      },
    },
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    archiveLabel();
    setOpen(false);
  };
  const archiveLabel = () => update();

  return (
    <>
      <Button
        label={record.isArchived ? 'Restore' : undefined}
        onClick={handleClick}
        disabled={isLoading}
        startIcon={record.isArchived ? undefined : <DeleteIcon />}
        color={record.isArchived ? 'info' : 'error'}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`Confirm Label ${record.isArchived ? 'Restore' : 'Delete'}`}
        content={`Are you sure you want to ${action.toLowerCase()} ${
          record.name
        }? This will impact all associated campaigns and line items. Google Ad Manager (GAM) will need to be updated accordingly.`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

// Change the name of the label to allow for the same name to be used again
function archiveName(name: string) {
  return `${name}__archived__${uuid()}`;
}

function restoreName(archivedName: string) {
  const archiveIndex = archivedName.indexOf('__archived__');
  if (archiveIndex > -1) {
    return archivedName.substring(0, archiveIndex);
  }
  return archivedName;
}
