import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { useState } from 'react';
import {
  Button,
  useCreate,
  useGetList,
  useListContext,
  useNotify,
  useRecordContext,
  useUnselectAll,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { LabelSelectionModal } from './label-selection-modal';
import { GetLabelsForLineItemDocument, Label, LineItemLabel } from 'src/generated/client';

export const AddLineItemLabelButton = () => {
  const notify = useNotify();
  const client = useApolloClient();
  const record = useRecordContext();
  const { selectedIds } = useListContext();
  const lineItemIds = selectedIds ?? [record?.id];

  const [selectedLabelId, setSelectedLabelId] = useState<Label['id']>('');
  const [open, setOpen] = useState(false);

  const [create, { isLoading }] = useCreate<LineItemLabel>();
  const unselectAll = useUnselectAll(`campaigns.${record?.id}.line_items`);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLabelSelect = (labelId: string) => setSelectedLabelId(labelId);
  const refetchLabels = () => client.refetchQueries({ include: [GetLabelsForLineItemDocument] });

  const handleConfirm = () => {
    lineItemIds.forEach(placementsLineItemId => {
      create(
        'lineItemLabels',
        { data: { placementsLineItemId, labelId: selectedLabelId } },
        {
          onSuccess: () => {
            notify('Labels assigned successfully');
            unselectAll();
            handleClose();
            refetchLabels();
          },
          onError: error => {
            notify(`Error: ${error}`, { type: 'warning' });
            refetchLabels(); // Refetch even when we fail to insert duplicate label. Remaining labels will still insert correctly.
          },
        },
      );
    });
  };

  return (
    <>
      <Button
        label="Add Line Item Exclusion Label"
        onClick={handleOpen}
        disabled={lineItemIds.length === 0}
        color="primary"
        startIcon={<AddIcon />}
      />
      <LabelSelectionModal
        open={open}
        selectedLabelId={selectedLabelId}
        onClose={handleClose}
        onLabelSelect={handleLabelSelect}
        onConfirm={handleConfirm}
        confirmDisabled={isLoading || !selectedLabelId}
        title="Add Line Item Exclusion Label"
      />
    </>
  );
};
