import { Button, useResourceContext, useRecordContext, useTheme } from 'react-admin';
import Launch from '@mui/icons-material/Launch';
import { Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';

/** A Button that when clicked, opens a browser tab to the relevant page in Placements.IO */
export const PlacementsButton = () => {
  const record = useRecordContext();
  const resource = useResourceContext();

  return (
    <a href={`https://app.placements.io/${resource}/${record?.id}`} target="_blank" rel="noreferrer">
      <Button label="PIO" alignIcon="right" onClick={e => e.stopPropagation()}>
        <Launch />
      </Button>
    </a>
  );
};

export const GreenChip = () => <Chip icon={<CheckCircleIcon />} label="Approved" color="success" />;

export const YellowChip = () => {
  const [theme] = useTheme();
  const backgroundColor = theme === 'dark' ? '#FFFFA7' : '#ffff5b';
  return (
    <Chip
      icon={<CreateIcon />}
      label="Draft"
      color="success"
      sx={{ backgroundColor, color: 'black' }}
    />
  );
};