import { Chip } from '@mui/material';
import sortBy from 'lodash/sortBy';
import { LinearProgress, useNotify } from 'react-admin';
import {
  Label,
  useDeleteCampaignLabelMutation,
  useDeleteLineItemLabelMutation,
  useGetLabelsForCampaignQuery,
  useGetLabelsForLineItemQuery,
} from 'src/generated/client';
import CancelIcon from '@mui/icons-material/Cancel';
import { CampaignRecord, LineItemRecord } from '../campaigns';

type LineItemExclusionLabelChipsProps = { record: LineItemRecord; deletionEnabled?: boolean };

export const LineItemExclusionLabelChips = ({ record, deletionEnabled = false }: LineItemExclusionLabelChipsProps) => {
  const notify = useNotify();
  const { data, loading, error, refetch } = useGetLabelsForLineItemQuery({
    variables: { lineItemId: record?.id },
  });

  const [deleteLineItemLabelMutation, { loading: deleteLoading, error: deleteError }] = useDeleteLineItemLabelMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const handleDelete = async (labelId: string, event: React.MouseEvent) => {
    event.stopPropagation();

    try {
      await deleteLineItemLabelMutation({
        variables: {
          placementsLineItemId: record?.id,
          labelId,
        },
      });
      notify('Line item label deleted successfully');
    } catch (error) {
      console.error('Error deleting line item label:', error);
      notify('Error deleting line item label', { type: 'warning' });
    }
  };

  if (loading || !data?.lineItemLabels) return <LinearProgress />;

  const labels = data.lineItemLabels.nodes.map(node => node.label as Label);

  return (
    <ExclusionLabelChips
      labels={labels}
      deleteLoading={deleteLoading}
      deletionEnabled={deletionEnabled}
      handleDelete={handleDelete}
    />
  );
};

type CampaignExclusionLabelChipsProps = { record: CampaignRecord; deletionEnabled?: boolean };

export const CampaignExclusionLabelChips = ({ record, deletionEnabled = false }: CampaignExclusionLabelChipsProps) => {
  const notify = useNotify();
  const { data, loading, error, refetch } = useGetLabelsForCampaignQuery({
    variables: { campaignId: record?.id },
  });

  const [deleteCampaignLabelMutation, { loading: deleteLoading, error: deleteError }] = useDeleteCampaignLabelMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const handleDelete = async (labelId: string, event: React.MouseEvent) => {
    event.stopPropagation();

    try {
      await deleteCampaignLabelMutation({
        variables: {
          placementsCampaignId: record?.id,
          labelId,
        },
      });
      notify('Campaign label deleted successfully');
    } catch (error) {
      console.error('Error deleting campaign label:', error);
      notify(`Error deleting campaign label`, { type: 'warning' });
    }
  };

  if (loading || !data?.campaignLabels) return <LinearProgress />;

  const labels = data.campaignLabels.nodes.map(node => node.label as Label);

  return (
    <ExclusionLabelChips
      labels={labels}
      deleteLoading={deleteLoading}
      deletionEnabled={deletionEnabled}
      handleDelete={handleDelete}
    />
  );
};

type ExclusionLabelChipsProps = {
  labels: Label[];
  deleteLoading: boolean;
  deletionEnabled?: boolean;
  handleDelete: (labelId: string, event: React.MouseEvent) => void;
};

const ExclusionLabelChips = ({
  labels,
  deleteLoading,
  deletionEnabled = false,
  handleDelete,
}: ExclusionLabelChipsProps) => {
  const sortLabelsByName = (labels: Label[]) => sortBy(labels, [label => label?.name?.toLowerCase()]);
  const sortedLabels = sortLabelsByName(labels);
  const activeLabels = sortedLabels.filter(label => !label.isArchived);

  return (
    <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
      {activeLabels?.map(label => (
        <Chip
          key={label.id}
          label={label?.name}
          onDelete={deletionEnabled ? event => handleDelete(label?.id, event) : undefined}
          deleteIcon={deletionEnabled ? <CancelIcon /> : undefined}
          disabled={deleteLoading}
        />
      ))}
    </div>
  );
};
