import { Create, Edit, useNotify, useRedirect, useResourceContext } from 'react-admin';
import { BannerTargetingsForm } from './forms/banner-targetings';
import { useSearchParams } from 'react-router-dom';
import { useTransformWithCampaignId } from './forms/hooks/useTransformWithCampaignId';

interface BannerTargetingsCreateInitialValues {
  placementsLineItemId: string;
}

export const BannerTargetingsCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();
  const placementsLineItemId = searchParams.get('placementsLineItemId') ?? '';
  const transform = useTransformWithCampaignId();

  const resource = useResourceContext();

  const record: BannerTargetingsCreateInitialValues = {
    placementsLineItemId,
  };

  const onSuccess = () => {
    notify('Saved new Banner Targeting');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Create
      record={record}
      mutationOptions={{
        onSuccess,
      }}
      redirect="list"
      transform={transform}
    >
      <BannerTargetingsForm type="Create" />
    </Create>
  );
};

export const BannerTargetingsEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const onSuccess = ({ placementsLineItemId }: { placementsLineItemId: number }) => {
    notify('Saved Banner Targeting');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess,
      }}
      redirect="list"
    >
      <BannerTargetingsForm type="Update" />
    </Edit>
  );
};
