import axios, { AxiosRequestConfig } from 'axios';

export const uploadTargetListToS3 = async (
  file: File,
  targetListId: number,
  getToken: () => Promise<string | null>,
) => {
  const formData = new FormData();
  formData.append('targetListId', targetListId.toString());
  formData.append('file', file);

  const axiosConfig: AxiosRequestConfig = {
    headers: {
      'content-type': 'multipart/form-data',
      authorization: `Bearer ${await getToken()}`,
    },
    onUploadProgress: event => {
      console.log(`${file.name}\n`, `Current upload progress: `, Math.round((event.loaded * 100) / event.total));
    },
  };

  // should handle errors here
  const baseUrl = process.env.NEXT_PUBLIC_CSAPI_BASE_URL;
  const uploadUrl = `${baseUrl}/api/target-list-upload`;
  const response = await axios.post(uploadUrl, formData, axiosConfig);
  return response.data;
};
