import { useEffect, useState, PropsWithChildren } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  AutocompleteInput,
  DateFieldProps,
  FormDataConsumer,
  Labeled,
  Link,
  Loading,
  required,
  SelectInput,
  SelectInputProps,
  TextField,
  TextInput,
  useGetList,
  useGetOne,
  useInput,
} from 'react-admin';
import { ESTDateField } from '../custom-ra-fields/est-date-field';
import { DateTimePicker } from '@mui/x-date-pickers';
import { zonedTimeToUtc } from 'date-fns-tz';
import { NEW_YORK_TIMEZONE, toNewYorkTime } from './form-utils';

export const FormHeader = ({ children }: PropsWithChildren) => (
  <Typography variant="h5" gutterBottom>
    {children}
  </Typography>
);

export const FormSectionHeader = ({ children }: PropsWithChildren) => (
  <Typography variant="h6" gutterBottom sx={{ marginTop: '1.5rem' }}>
    {children}
  </Typography>
);

export const FormDivider = () => (
  <Box marginBottom={3}>
    <Divider />
  </Box>
);

type DateInputWrapperProps = {
  source: string;
  label: string;
  defaultValue?: Date;
  minDateTime?: Date;
  maxDateTime?: Date;
};

const DateInputWrapper = ({ source, label, defaultValue, minDateTime, maxDateTime }: DateInputWrapperProps) => {
  const {
    field: { value, onChange },
  } = useInput({ source });

  const dateTimeValue = value ?? defaultValue;

  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      // Convert the selected datetime to UTC before storing it in the form
      const utcDate = zonedTimeToUtc(newValue, NEW_YORK_TIMEZONE);
      onChange(utcDate.toISOString());
    } else {
      onChange(null);
    }
  };

  return (
    <DateTimePicker
      label={label}
      value={toNewYorkTime(dateTimeValue)}
      onChange={handleChange}
      minDateTime={minDateTime}
      maxDateTime={maxDateTime}
    />
  );
};

export type DateInputGroupProps = {
  minStartsAt: Date;
  maxEndsAt: Date;
};

export const DateInputGroup = ({ minStartsAt, maxEndsAt }: DateInputGroupProps) => (
  <Box display="flex">
    <Box marginRight="1rem">
      <DateInputWrapper source="startsAt" label="Starts At" defaultValue={minStartsAt} maxDateTime={maxEndsAt} />
    </Box>
    <DateInputWrapper source="endsAt" label="Ends At" defaultValue={maxEndsAt} minDateTime={minStartsAt} />
  </Box>
);

export const TitleInput = ({ label }: { label?: string }) => (
  <TextInput label={label} source="title" fullWidth resettable multiline validate={required()} />
);

interface SelectInputChoice {
  id: string;
  name: string;
}

const byNameAsc = (a: SelectInputChoice, b: SelectInputChoice) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const LineItemTargetListInput = ({ lineItemId }: { lineItemId: number }) => {
  const [choices, setChoices] = useState<SelectInputChoice[]>([] as SelectInputChoice[]);
  const targetListsResult = useGetList('targetLists', {
    pagination: { page: 1, perPage: 5000 },
    sort: { field: 'name', order: 'DESC' },
  });

  const bannerTargetingResult = useGetList('bannerTargeting', {
    pagination: { page: 1, perPage: 5000 },
    sort: { field: 'startsAt', order: 'DESC' },
    filter: { placementsLineItemId: lineItemId.toString() },
  });

  useEffect(() => {
    if (targetListsResult.isLoading || bannerTargetingResult.isLoading) return;
    if (targetListsResult.data && !choices.length) {
      const invalidTargetListIds =
        bannerTargetingResult?.data?.map(each => each.id).map(({ targetListId }) => targetListId) || [];
      const filteredChoices = targetListsResult.data.filter(
        ({ id }) => !invalidTargetListIds.includes(id),
      ) as SelectInputChoice[];
      setChoices(filteredChoices);
    }
  }, [targetListsResult, bannerTargetingResult]);

  return (
    <Box display="flex">
      <Box>
        <AutocompleteInput
          label="Target List"
          source="targetListId"
          choices={choices}
          sx={{ minWidth: 250 }}
          validate={validateTargetList}
        />
      </Box>
    </Box>
  );
};

const validateTargetList = (value: [] | undefined) => {
  return !value ? 'Target List is required' : undefined;
};

interface CampaignLineItemsSelectInputProps extends SelectInputProps {
  campaignId: string;
}

export const CampaignLineItemsSelectInput = ({ campaignId, ...rest }: CampaignLineItemsSelectInputProps) => {
  const { data, isLoading, error } = useGetOne('campaigns', { id: campaignId });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return null;

  const choices = [...((data?.lineItems ?? []) as SelectInputChoice[])];
  choices.sort(byNameAsc);

  return (
    <Box display="flex" flexWrap="wrap" rowGap="0.5rem" columnGap="1rem">
      <Box>
        <SelectInput
          label="Line Item Name"
          source="placementsLineItemId"
          choices={choices}
          {...rest}
          validate={required()}
        />
      </Box>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <Box display="flex" gap="1rem">
              <CurrentLineItemDateField
                lineItemId={formData?.placementsLineItemId}
                label="Line Item Start Date"
                source="startDate"
              />
              <CurrentLineItemDateField
                lineItemId={formData?.placementsLineItemId}
                label="Line Item End Date"
                source="endDate"
              />
            </Box>
            <CurrentLineItemLinkedNameField
              lineItemId={formData?.placementsLineItemId}
              label="Line Item Name"
              source="name"
            />
          </>
        )}
      </FormDataConsumer>
    </Box>
  );
};

interface CurrentLineItemFieldProps extends DateFieldProps {
  lineItemId: string;
  source: string;
  label: string;
}

const CurrentLineItemDateField = ({ lineItemId, source, label }: CurrentLineItemFieldProps) => {
  const { data, isLoading, error } = useGetOne('line_items', { id: lineItemId });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return null;

  return (
    <Labeled label={label}>
      <ESTDateField record={data} source={source} variant="body1" />
    </Labeled>
  );
};

const CurrentLineItemLinkedNameField = ({ lineItemId, source, label }: CurrentLineItemFieldProps) => {
  const { data, isLoading, error } = useGetOne('line_items', { id: lineItemId });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return null;

  return (
    <Labeled label={label}>
      <Link to={`/line_items/${lineItemId}/show`}>
        <TextField record={data} source={source} variant="body1" />
      </Link>
    </Labeled>
  );
};

export const CurrentLineItemDateInputGroup = () => (
  <FormDataConsumer>
    {({ formData }) => {
      return <DateInputGroupForLineItem lineItemId={formData.placementsLineItemId} />;
    }}
  </FormDataConsumer>
);

type DateInputGroupForLineItemProps = {
  lineItemId: string;
};

const DateInputGroupForLineItem = ({ lineItemId }: DateInputGroupForLineItemProps) => {
  const { data, isLoading, error } = useGetOne('line_items', { id: lineItemId });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return null;

  const lineItemStartDate = new Date(data.startDate);
  const lineItemEndDate = new Date(data.endDate);

  return <DateInputGroup minStartsAt={lineItemStartDate} maxEndsAt={lineItemEndDate} />;
};
