import { DateField, DateFieldProps } from 'react-admin';
import { NEW_YORK_TIMEZONE } from '../forms/form-utils';

export const ESTDateField = (props: DateFieldProps) => {
  const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h12',
    timeZone: NEW_YORK_TIMEZONE,
  };

  return <DateField {...props} options={dateTimeFormatOptions} locales="en-US" sx={{ textWrap: 'nowrap' }} />;
};
