import Papa, { ParseResult } from 'papaparse';

export const extractNpis = async (targetListFile: File): Promise<Number[] | any> => {
  if (!targetListFile) return [];
  const parsedResults: ParseResult<any> = await parseFile(targetListFile);
  const npis = getNpiArray(parsedResults);

  return npis;
};

export const createFormattedTargetList = async (npis: Array<number>, targetListID: number) => {
  const headers = ['npi_number', 'target_list_id'];
  const rows = npis.map(npi => [npi, targetListID]);
  const unparsed = Papa.unparse([headers, ...rows], {
    delimiter: '\t',
  });
  const blob = new Blob([unparsed], {
    type: 'text/plain',
  });
  const file = new File([blob], `${targetListID}.txt`);

  return file;
};

const parseFile = async (targetListFile: File) => {
  return new Promise<ParseResult<any>>((resolve, reject) => {
    Papa.parse(targetListFile, {
      complete: (results: ParseResult<any>, file: File) => {
        resolve(results);
      },
    });
  });
};

const getNpiArray = (results: ParseResult<any>) => {
  const npiColumnIndexes = findNpiColumnIndexes(results);
  const npiArray = findAllNpiNumbers(npiColumnIndexes, results);
  return npiArray;
};

const findNpiColumnIndexes = (results: ParseResult<any>) => {
  const headers = results?.data && results?.data[0];

  const npiColumnIndexes = headers?.reduce((accumulator: Array<number>, header: string, index: number) => {
    header?.toLowerCase().includes('npi') && accumulator.push(index);
    return accumulator;
  }, [] as Array<number>);

  return npiColumnIndexes;
};

const findAllNpiNumbers = (npiColumnIndexes: Array<number>, results: ParseResult<any>) => {
  const data = results?.data;
  const allNpiNumbers: Array<number> = [];

  data?.forEach((row: Array<string>) => {
    row.forEach((cell: string, j: number) => {
      if (
        npiColumnIndexes.includes(j) &&
        cell.length === 10 &&
        !isNaN(parseInt(cell)) &&
        parseInt(cell) <= 2147483647 //GraphQL only supports 32 bit int max.
      ) {
        allNpiNumbers.push(parseInt(cell));
      }
    });
  });

  return allNpiNumbers;
};
