import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FileField,
  FileInput,
  FunctionField,
  List,
  NumberField,
  required,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  FilterButton,
  FilterForm,
  Link,
} from 'react-admin';
import { Fragment } from 'react';
import { npiNotification } from '../utility/target-list-upload/npi-notification';
import { FormHeader } from './forms/form-components';
import { includesInsensitive } from './forms/form-utils';

const targetListFilters: JSX.Element[] = [
  <TextInput label="Target List Name" source="name" {...includesInsensitive} alwaysOn />,
  <TextInput label="Target List Description" source="description" {...includesInsensitive} alwaysOn />,
];

const LineItemLinks = ({ lineItemIdsList }: { lineItemIdsList: any[] }) => {
  if (!lineItemIdsList) return null;

  return (
    <>
      {lineItemIdsList.map((id, index) => (
        <Fragment key={id}>
          <Link to={`/line_items/${id}/show`}>{id}</Link>
          {index < lineItemIdsList.length - 1 ? ', ' : ''}
        </Fragment>
      ))}
    </>
  );
};
const TargetListsListActions = () => (
  <div style={{ display: 'flex', flexGrow: 1 }}>
    <FilterForm filters={targetListFilters} style={{ width: '100%' }} />
    <FilterButton filters={targetListFilters} />
    <CreateButton label="Upload" style={{ justifySelf: 'flex-end' }} />
  </div>
);

export const TargetListsList = () => (
  <List actions={<TargetListsListActions />} perPage={25}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <FunctionField
        render={(record: any) => (record.npiCount ? Number(record.npiCount).toLocaleString() : '')}
        label="NPI Count"
        textAlign="center"
      />
      <FunctionField
        render={(record: any) => <LineItemLinks lineItemIdsList={record.bannerPlacementLineItemIdsList} />}
        label="Banner Line Items"
      />

      <FunctionField
        render={(record: any) => <LineItemLinks lineItemIdsList={record.clicksPlacementLineItemIdsList} />}
        label="Clicks Line Items"
      />

      <FunctionField
        render={(record: any) => <LineItemLinks lineItemIdsList={record.fmaPlacementLineItemIdsList} />}
        label="FMA Line Items"
      />

      <EditButton />
    </Datagrid>
  </List>
);

export const TargetListCreate = () => {
  const notify = useNotify();

  return (
    <Create>
      <SimpleForm>
        <FormHeader>Create Target List</FormHeader>
        <TextInput source="name" />
        <TextInput multiline fullWidth source="description" />
        <FileInput
          source="targetListFile"
          label="Target List File"
          accept=".txt, .csv"
          validate={required()}
          helperText="Files should be in tab- or comma-separated values format (.txt or .csv). Please indicate which column contains NPI numbers by including 'NPI' in first (header) row."
          options={{ onDrop: file => npiNotification(file[0], notify) }}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export const TargetListEdit = () => {
  const notify = useNotify();
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        <FormHeader>Update Target List</FormHeader>
        <TextInput source="name" />
        <TextInput multiline fullWidth source="description" />
        <TextField source="uploadedFilename" label="Last Uploaded Filename" />
        <DateField showTime={true} source="uploadedAt" label="Last Uploaded At" />
        <NumberField source="npiCount" label="NPI Count" />
        <FileInput
          source="targetListFile"
          label="Target List File"
          accept=".txt, .csv"
          helperText="Files should be in tab- or comma-separated values format (.txt or .csv). Please indicate which column contains NPI numbers by including 'NPI' in first (header) row."
          options={{ onDrop: file => npiNotification(file[0], notify) }}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};
