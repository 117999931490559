import { DataProvider } from 'react-admin';

import { DataProviderForResources } from './data-provider-for-resources';
import { DelegatingDataProvider } from './delegating-data-provider';

export class CompositeDataProvider extends DelegatingDataProvider implements DataProvider {
  dataProviders: DataProviderForResources[];

  constructor(dataProviders: DataProviderForResources[], defaultDataProvider: DataProvider) {
    super(defaultDataProvider);
    this.dataProviders = dataProviders;
  }

  protected delegate(functionName: string, resource: string, params: any) {
    const dataProvider = this.dataProviders.find(dp => dp.forResources().includes(resource));

    if (!dataProvider) {
      return super.delegate(functionName, resource, params);
    }
    return dataProvider[functionName](resource, params);
  }
}
