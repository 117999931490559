import { utcToZonedTime } from 'date-fns-tz';
import { FilterSpec } from 'ra-postgraphile';
import { regex, required } from 'react-admin';
import { ClicksCreative } from 'src/generated/client';

export const NEW_YORK_TIMEZONE = 'America/New_York';

export const convertDateToUTC = (date: Date) => Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

export const removeTimeFromDate = (dateString: string) => dateString?.split('T')[0];

export const convertISODateStringToUTC = (dateString: string) =>
  new Date(convertDateToUTC(new Date(removeTimeFromDate(dateString))));

export const toNewYorkTime = (date: string | number | Date) => {
  return utcToZonedTime(date, NEW_YORK_TIMEZONE);
};

export const validateDateOrder =
  (type: 'startsAt' | 'endsAt') =>
  (value: ClicksCreative['startsAt'] | ClicksCreative['endsAt'], allValues: ClicksCreative) => {
    const { endsAt, startsAt } = allValues;
    const startDate = convertISODateStringToUTC(startsAt);
    const endDate = convertISODateStringToUTC(endsAt);

    if (startDate > endDate) {
      return type === 'startsAt' ? 'Starts at must be before Ends at' : 'Ends at must be after Starts at';
    }

    if (startDate === endDate) {
      return 'Dates cannot be on the same day';
    }
    return undefined;
  };

export const validateUrlFormat = regex(
  /(http(s)?\:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
  'Must be a valid URL',
);

export const validateDateOnOrAfter = (minDate: Date) => (inputIsoDateString: string) => {
  const inputDate = new Date(removeTimeFromDate(inputIsoDateString));
  return inputDate >= minDate
    ? undefined
    : `Starts at must be on or after ${minDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'utc',
      })}`;
};

export const validateDateOnOrBefore = (maxDate: Date) => (inputIsoDateString: string) => {
  const inputDate = new Date(removeTimeFromDate(inputIsoDateString));
  return inputDate <= maxDate
    ? undefined
    : `Ends at must be on or before ${maxDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'utc',
      })}`;
};

export const validateStartsAt = (minStartsAt: Date) => [
  required(),
  validateDateOrder('startsAt'),
  validateDateOnOrAfter(minStartsAt),
];
export const validateEndsAt = (maxEndsAt: Date) => [
  required(),
  validateDateOrder('endsAt'),
  validateDateOnOrBefore(maxEndsAt),
];

export const createFilterOperator = (operator: FilterSpec['operator']) => {
  return {
    parse: (value: string) => ({
      operator,
      value,
    }),
    format: (filter: FilterSpec) => filter?.value || '',
  };
};

export const includesInsensitive = createFilterOperator('includesInsensitive');
