import {
  BooleanInput,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  required,
  SimpleFormProps,
  SelectInput,
  Loading,
  useGetOne,
} from 'react-admin';
import {
  CampaignLineItemsSelectInput,
  CurrentLineItemDateInputGroup,
  FormDivider,
  FormHeader,
  FormSectionHeader,
  TitleInput,
} from './form-components';
import { validateUrlFormat } from './form-utils';
import range from 'lodash/range';
import { PreviewFMAButton } from '../fma-creatives';
import { CreativeToolbar, AutocompleteInput } from './shared';
import { useRecordContext } from 'react-admin';

export interface CreativesFormProps extends Partial<SimpleFormProps> {
  type: 'Create' | 'Update';
}

export const FmaCreativeForm = ({ type, ...rest }: CreativesFormProps) => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetOne('line_items', { id: record.placementsLineItemId });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR: {error.toString()}</p>;
  }
  if (!data) return null;

  const lineItem = data;

  const defaultValues = {
    ...rest.defaultValues,
    startsAt: lineItem.startDate,
    endsAt: lineItem.endDate,
  };

  return (
    <SimpleForm
      {...rest}
      defaultValues={defaultValues}
      toolbar={<CreativeToolbar />}
      mode="onBlur"
      reValidateMode="onBlur"
    >
      <FormHeader>{`${type} FMA Creative, Targeting & Pacing`}</FormHeader>

      <CampaignLineItemsSelectInput campaignId={lineItem.campaign.id} />

      <FormSectionHeader>Creative Details</FormSectionHeader>

      <BooleanInput source="isApproved" label="Approved" defaultValue={false} />

      <PreviewFMAButton />

      <TextInput label="Flight / Segment Name" source="name" resettable fullWidth multiline validate={required()} />

      <CurrentLineItemDateInputGroup />

      <TitleInput label="FMA Title" />
      <TextInput
        label="Company / Brand Name"
        source="companyName"
        fullWidth
        resettable
        multiline
        validate={required()}
      />
      <TextInput
        variant="outlined"
        label="FMA Body HTML"
        source="bodyHtml"
        multiline
        fullWidth
        minRows={10}
        maxRows={30}
        validate={required()}
      />
      <TextInput
        variant="outlined"
        label="FMA ISI HTML"
        source="isiHtml"
        multiline
        fullWidth
        minRows={10}
        maxRows={30}
      />

      <TextInput source="jobNumber" resettable sx={{ display: 'inline-flex', marginRight: '1rem' }} />
      <TextInput
        label="Touchpoint 2 URL"
        source="showMoreInfoUrl"
        fullWidth
        resettable
        validate={[validateUrlFormat]}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <BooleanInput
            label={`Countdown timer on FMA ${formData.enableCountdownCloseButton ? 'ON' : 'OFF'}`}
            source="enableCountdownCloseButton"
          />
        )}
      </FormDataConsumer>
      <FormDivider />
      <FormSectionHeader>Targeting Details</FormSectionHeader>

      <AutocompleteInput resource='targetLists' source='targetListIdsList' validate={validateTargeting}/>
      <FormDivider />

      <FormSectionHeader>Pacing</FormSectionHeader>
      <SelectInput
        label="Show number of times per week"
        source="viewsPerWeek"
        validate={[required()]}
        defaultValue={2}
        sx={{ minWidth: 250 }}
        helperText="Between 1 and 4"
        choices={range(1, 5).map(value => {
          return {
            id: value,
            name: `${value}`,
          };
        })}
      />
    </SimpleForm>
  );
};

interface FmaCreativeTargetingInputs {
  isApproved: boolean;
  targetListIdsList?: number[];
}

interface FmaCreativeTargetingErrors {
  targetListIdsList?: string;
}

const validateTargeting = (value: any, allValues: any) => {
  if (allValues.isApproved && (allValues.targetListIdsList?.length ?? 0) === 0) {
    return 'If Target List targeting is selected, you must select at least one target list';
  }
  return undefined;
};