import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';

export class DelegatingDataProvider implements DataProvider {
  constructor(private dataProvider: DataProvider) {}

  protected delegate(functionName: string, resource: string, params: any) {
    return this.dataProvider[functionName](resource, params);
  }

  getList(resource: string, params: GetListParams) {
    return this.delegate('getList', resource, params);
  }
  getOne(resource: string, params: GetOneParams) {
    return this.delegate('getOne', resource, params);
  }
  getMany(resource: string, params: GetManyParams) {
    return this.delegate('getMany', resource, params);
  }
  getManyReference(resource: string, params: GetManyReferenceParams) {
    return this.delegate('getManyReference', resource, params);
  }
  create(resource: string, params: CreateParams) {
    return this.delegate('create', resource, params);
  }
  update(resource: string, params: UpdateParams) {
    return this.delegate('update', resource, params);
  }
  updateMany(resource: string, params: UpdateManyParams) {
    return this.delegate('updateMany', resource, params);
  }
  delete(resource: string, params: DeleteParams) {
    return this.delegate('delete', resource, params);
  }
  deleteMany(resource: string, params: DeleteManyParams) {
    return this.delegate('deleteMany', resource, params);
  }
  duplicate(resource: string, params: CreateParams) {
    return this.delegate('duplicate', resource, params);
  }
}
