import {
  List,
  Datagrid,
  FunctionField,
  Labeled,
  ReferenceManyField,
  Show,
  TextField,
  TextInput,
  DateInput,
} from 'react-admin';
import { titleize, singularize } from 'inflection';
import { Divider, CardContent, CardHeader } from '@mui/material';

import { Children, isValidElement, ReactNode } from 'react';
import { PlacementsButton } from './shared';
import { ESTDateField } from './custom-ra-fields/est-date-field';
import { useRecordContext, useResourceContext } from 'react-admin';
import { AddCampaignLabelButton } from './exclusion-labels/add-campaign-label-button';
import { AddLineItemLabelButton } from './exclusion-labels/add-line-item-label-button';
import { CampaignExclusionLabelChips, LineItemExclusionLabelChips } from './exclusion-labels/exclusion-label-chips';

type CampaignAdvertiser = {
  id: string;
  name: string;
};

type CampaignLineItem = {
  id: string;
  name: string;
};

export type CampaignRecord = {
  advertiser: CampaignAdvertiser;
  archived: boolean;
  campaignNumber: number;
  endDate: string;
  id: string;
  lineItems: CampaignLineItem[];
  name: string;
  startDate: string;
};

type LineItemProduct = {
  id: string;
  name: string;
};

type LineItemCustomFields = {
  hcpData?: string;
  revenue: string;
  revenueUnits: string;
};

type LineItemCampaign = {
  id: string;
  name: string;
};

export type LineItemRecord = {
  archived: boolean;
  campaign: LineItemCampaign;
  customFields: LineItemCustomFields;
  endDate: string;
  id: string;
  name: string;
  product: LineItemProduct;
  startDate: string;
};

const campaignListFilters: JSX.Element[] = [
  <TextInput label="Advertiser" source="advertiserName" alwaysOn />,
  <DateInput label="Starting On / After" source="startDate" alwaysOn />,
];

export const CampaignList = () => (
  <List
    filters={campaignListFilters}
    sort={{ field: 'startDate', order: 'DESC' }}
    perPage={25}
    filterDefaultValues={{ startDate: '2022-01-01' }}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="advertiser.name" label="Advertiser" />
      <FunctionField
        label="Campaign Name"
        render={(record: CampaignRecord) => {
          return `#${record.campaignNumber} ${record.name}`;
        }}
      />
      <ESTDateField source="startDate" textAlign="center" />
      <ESTDateField source="endDate" textAlign="center" />
      <FunctionField
        label="Campaign Exclusion Labels"
        render={(record: CampaignRecord) => <CampaignExclusionLabelChips record={record} />}
      />

      <PlacementsButton />
    </Datagrid>
  </List>
);

export const CampaignShow = () => (
  <Show>
    <CampaignShowLayout>
      <TextField source="name" />
      <ESTDateField source="startDate" label="Campaign Start Date" textAlign="center" />
      <ESTDateField source="endDate" label="Campaign End Date" textAlign="center" />
    </CampaignShowLayout>
  </Show>
);

const LineItemsDatagrid = () => (
  <CardContent>
    <CardHeader title="Line Items" />
    <ReferenceManyField reference="line_items" target="campaigns" sort={{ field: 'startDate', order: 'DESC' }}>
      <Datagrid rowClick="show" bulkActionButtons={<AddLineItemLabelButton />}>
        <TextField source="id" />
        <TextField source="name" />
        <ESTDateField source="startDate" />
        <ESTDateField source="endDate" />
        <TextField source="product.name" label="Product" />
        <TextField source="customFields.hcpData" label="HCP Data?" />
        <FunctionField
          label="Exclusion Labels"
          render={(record: LineItemRecord) => <LineItemExclusionLabelChips record={record} />}
        />
        <PlacementsButton />
      </Datagrid>
    </ReferenceManyField>
  </CardContent>
);

const CampaignCardChild = ({ field }: { field: React.ReactNode }) => {
  if (!field || !isValidElement<any>(field)) return null;
  return (
    <div key={field.props.source}>
      <Labeled source={field.props.source} fullWidth={field.props.fullWidth}>
        {field}
      </Labeled>
    </div>
  );
};

const CampaignShowLayout = ({ children }: CampaignShowLayoutProps) => {
  const record = useRecordContext<CampaignRecord>();
  const resource = useResourceContext();

  return (
    <>
      <CardContent>
        <CardHeader title={`${titleize(singularize(resource ?? ''))} #${record?.id}`} />
        {Children.map(children, field => (
          <CampaignCardChild field={field} />
        ))}
        <CampaignCardChild field={<TextField label="Campaign Exclusion Labels" />} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 5 }}>
          <CampaignExclusionLabelChips record={record} deletionEnabled />
          <AddCampaignLabelButton />
        </div>
      </CardContent>
      <Divider />
      <LineItemsDatagrid />
    </>
  );
};

export interface CampaignShowLayoutProps {
  children: ReactNode;
}
