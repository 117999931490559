import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { uploadTargetListToS3 } from '../../utility/target-list-upload/s3-upload';
import {
  CreateTargetListMutation,
  CreateTargetListMutationVariables,
  CreateTargetListDocument,
  DuplicateClicksCreativeDocument,
  DuplicateClicksCreativeMutation,
  DuplicateClicksCreativeMutationVariables,
  DuplicateFmaCreativeDocument,
  DuplicateFmaCreativeMutation,
  DuplicateFmaCreativeMutationVariables,
  UpdateTargetListDocument,
  UpdateTargetListMutation,
  UpdateTargetListMutationVariables,
  UpdateFmaCreativeMutationVariables,
  UpdateFmaCreativeMutation,
  CreateClicksCreativeMutation,
  CreateClicksCreativeMutationVariables,
  UpdateClicksCreativeDocument,
  CreateClicksCreativeDocument,
  SetFmaCreativeTargetListsMutation,
  SetFmaCreativeTargetListsMutationVariables,
  SetFmaCreativeTargetListsDocument,
  CreateFmaCreativeMutationVariables,
  CreateFmaCreativeDocument,
  CreateFmaCreativeMutation,
  SetClicksCreativeListsMutation,
  SetClicksCreativeListsMutationVariables,
  SetClicksCreativeListsDocument,
  UpdateFmaCreativeDocument,
  UpdateClicksCreativeMutation,
  UpdateClicksCreativeMutationVariables,
  ClicksCreative,
  ArchiveLabelMutation,
  ArchiveLabelMutationVariables,
  ArchiveLabelDocument,
  ClicksCreativeTargetingType,
} from 'src/generated/client';

export interface CreateTargetListVariables extends CreateTargetListMutationVariables {
  targetListFile: { rawFile: File };
  npisList: number[];
}

export const createTargetList = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: CreateTargetListVariables,
  getToken: () => Promise<string | null>,
) => {
  const createResult = await apolloClient.mutate<CreateTargetListMutation, CreateTargetListMutationVariables>({
    mutation: CreateTargetListDocument,
    variables: {
      description: variables.description,
      name: variables.name,
      uploadedFilename: variables.targetListFile.rawFile.name,
    },
  });

  const newTargetListId = createResult.data?.createTargetList?.targetList?.id;

  let uploadResponse;
  if (newTargetListId) {
    uploadResponse = await uploadTargetListToS3(variables.targetListFile.rawFile, newTargetListId, getToken);
  }
  return {
    data: {
      ...createResult?.data?.createTargetList?.targetList,
      npiCount: uploadResponse?.data?.uploadedCount ?? 0,
    },
  };
};

export interface UpdateTargetListVariables extends UpdateTargetListMutationVariables {
  targetListFile?: { rawFile: File };
  npisList?: number[];
}

export const updateTargetList = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: UpdateTargetListVariables,
  getToken: () => Promise<string | null>,
) => {
  const input: UpdateTargetListMutationVariables = {
    id: variables.id,
    description: variables.description,
    name: variables.name,
  };

  const fileToUpload = variables?.targetListFile?.rawFile;

  if (fileToUpload) {
    input.uploadedFilename = fileToUpload.name;
  }

  const updateResult = await apolloClient.mutate<UpdateTargetListMutation, UpdateTargetListMutationVariables>({
    mutation: UpdateTargetListDocument,
    variables: input,
  });

  let uploadResponse;
  if (fileToUpload) {
    uploadResponse = await uploadTargetListToS3(fileToUpload, input.id, getToken);
  }

  return {
    data: {
      ...updateResult?.data?.updateTargetList?.targetList,
      npiCount: uploadResponse?.data?.uploadedCount ?? 0,
    },
  };
};

export const duplicateClicksCreative = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: DuplicateClicksCreativeMutationVariables,
) => {
  const duplicateMutationResult = await apolloClient.mutate<
    DuplicateClicksCreativeMutation,
    DuplicateClicksCreativeMutationVariables
  >({
    mutation: DuplicateClicksCreativeDocument,
    variables,
  });

  const duplicatedCreative = duplicateMutationResult?.data?.duplicateClicksCreative?.clicksCreative;

  return {
    data: duplicatedCreative,
    id: duplicatedCreative?.id,
  };
};

export const createFmaCreative = async (apolloClient: ApolloClient<NormalizedCacheObject>, variables: any) => {
  const { targetListIdsList, ...fmaCreative } = variables;
  const createMutationResult = await apolloClient.mutate<CreateFmaCreativeMutation, CreateFmaCreativeMutationVariables>(
    {
      mutation: CreateFmaCreativeDocument,
      variables: {
        input: {
          fmaCreative,
        },
      },
    },
  );

  const newFmaCreativeId = createMutationResult.data?.createFmaCreative?.fmaCreative?.id;

  if (newFmaCreativeId) {
    await apolloClient.mutate<SetFmaCreativeTargetListsMutation, SetFmaCreativeTargetListsMutationVariables>({
      mutation: SetFmaCreativeTargetListsDocument,
      variables: {
        input: {
          fmaCreativeId: newFmaCreativeId,
          targetListIds: targetListIdsList ?? [],
        },
      },
    });
  }

  return {
    data: createMutationResult?.data?.createFmaCreative?.fmaCreative,
  };
};

export const updateFmaCreative = async (apolloClient: ApolloClient<NormalizedCacheObject>, variables: any) => {
  const { id, targetListIdsList, __typename, ...patch } = variables;

  // hasActivityData is generated from a function and is readonly
  delete patch.hasActivityData;

  const updateMutationResult = await apolloClient.mutate<UpdateFmaCreativeMutation, UpdateFmaCreativeMutationVariables>(
    {
      mutation: UpdateFmaCreativeDocument,
      variables: {
        id,
        patch,
        targetListIds: targetListIdsList,
      },
    },
  );

  return {
    data: {
      ...updateMutationResult?.data?.updateFmaCreative?.fmaCreative,
      placementsLineItemId: variables.placementsLineItemId,
    },
  };
};

export const duplicateFmaCreative = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: DuplicateFmaCreativeMutationVariables,
) => {
  const duplicateMutationResult = await apolloClient.mutate<
    DuplicateFmaCreativeMutation,
    DuplicateFmaCreativeMutationVariables
  >({
    mutation: DuplicateFmaCreativeDocument,
    variables,
  });

  const duplicatedCreative = duplicateMutationResult?.data?.duplicateFmaCreative?.fmaCreative;

  return {
    data: duplicatedCreative,
    id: duplicatedCreative?.id,
  };
};

export const createClicksCreative = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: ClicksCreative,
) => {
  const { id, targetListIdsList, professionIdsList, specialtyIdsList, hideOnDaysList, __typename, ...clicksCreative } =
    variables;
  const createMutationResult = await apolloClient.mutate<
    CreateClicksCreativeMutation,
    CreateClicksCreativeMutationVariables
  >({
    mutation: CreateClicksCreativeDocument,
    variables: {
      input: { clicksCreative },
    },
  });

  const newClicksCreativeId = createMutationResult.data?.createClicksCreative?.clicksCreative?.id;

  if (newClicksCreativeId) {
    await apolloClient.mutate<SetClicksCreativeListsMutation, SetClicksCreativeListsMutationVariables>({
      mutation: SetClicksCreativeListsDocument,
      variables: {
        id: newClicksCreativeId,
        daysToHide: hideOnDaysList ?? [],
        targetListIds: targetListIdsList ?? [],
        professionIds: professionIdsList ?? [],
        specialtyIds: specialtyIdsList ?? [],
      },
    });
  }

  return {
    data: createMutationResult?.data?.createClicksCreative?.clicksCreative,
  };
};

export const updateClicksCreative = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: ClicksCreative,
) => {
  const { id, targetListIdsList, professionIdsList, specialtyIdsList, hideOnDaysList, __typename, ...patch } =
    variables;
  // hasActivityData is generated from a function and is readonly
  delete patch.hasActivityData;

  const targetingVariables =
    patch.targetingType === ClicksCreativeTargetingType['TargetList']
      ? { targetListIds: targetListIdsList ?? [], professionIds: [], specialtyIds: [] }
      : {
          professionIds: professionIdsList ?? [],
          specialtyIds: specialtyIdsList ?? [],
          targetListIds: [],
        };

  const updateMutationResult = await apolloClient.mutate<
    UpdateClicksCreativeMutation,
    UpdateClicksCreativeMutationVariables
  >({
    mutation: UpdateClicksCreativeDocument,
    variables: {
      id,
      patch,
      daysToHide: hideOnDaysList ?? [],
      ...targetingVariables,
    },
  });

  return {
    data: {
      ...updateMutationResult?.data?.updateClicksCreative?.clicksCreative,
      placementsLineItemId: variables.placementsLineItemId,
    },
  };
};

export const archiveLabel = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: ArchiveLabelMutationVariables,
) => {
  const { id, isArchived, name, updatedBy } = variables;

  const updateMutationResult = await apolloClient.mutate<ArchiveLabelMutation, ArchiveLabelMutationVariables>({
    mutation: ArchiveLabelDocument,
    variables: {
      id,
      isArchived,
      name,
      updatedBy,
    },
  });

  return {
    data: updateMutationResult?.data?.updateLabel?.label,
  };
};
