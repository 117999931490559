import { stringify } from 'querystring';
import {
  fetchUtils,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin';
import type { RaRecord } from 'react-admin';

import { DataProviderForResources } from './data-provider-for-resources';

export class PlacementsRestDataProvider implements DataProviderForResources {
  constructor(private baseUrl: string, private getToken: () => Promise<string | null>) {}

  async getList<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<RecordType>> {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      page,
      perPage,
      sort: field,
      order,
      filter: JSON.stringify(params.filter),
    };
    const url = `${this.baseUrl}/${resource}?${stringify(query)}`;
    const response = await fetchUtils
      .fetchJson(url, {
        credentials: 'same-origin',
        user: { authenticated: true, token: `Bearer ${await this.getToken()}` },
      })
      .then(({ json }) => {
        return {
          data: json.data,
          total: json.total,
        };
      });
    return response;
  }

  async getOne<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<RecordType>> {
    const id = params.id;
    const url = `${this.baseUrl}/${resource}/${id}`;
    console.log(`Client, calling getOne with URL: ${url}`);
    const response = await fetchUtils
      .fetchJson(url, {
        user: { authenticated: true, token: `Bearer ${await this.getToken()}` },
      })
      .then(({ json }) => {
        return {
          data: json.data,
        };
      });
    return response;
  }

  async getMany<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<RecordType>> {
    const ids = params.ids;
    const idsCommaSeparated = ids.join(',');
    const query = {
      filterIds: idsCommaSeparated,
    };
    const url = `${this.baseUrl}/${resource}?${stringify(query)}`;
    const response = await fetchUtils
      .fetchJson(url, {
        user: { authenticated: true, token: `Bearer ${await this.getToken()}` },
      })
      .then(({ json }) => {
        return {
          data: json.data,
        };
      });
    return response;
  }

  async getManyReference<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<RecordType>> {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const target = params.target;
    const id = params.id;

    const query = {
      page,
      perPage,
      sort: field,
      order,
      filter: JSON.stringify(params.filter),
    };
    const url = `${this.baseUrl}/${target}/${id}/${resource}?${stringify(query)}`;
    const response = await fetchUtils
      .fetchJson(url, {
        user: { authenticated: true, token: `Bearer ${await this.getToken()}` },
      })
      .then(({ json }) => {
        return {
          data: json.data,
          total: json.total,
        };
      });
    return response;
  }

  create() {
    return this.notImplemented();
  }
  update() {
    return this.notImplemented();
  }
  updateMany() {
    return this.notImplemented();
  }
  delete() {
    return this.notImplemented();
  }
  deleteMany() {
    return this.notImplemented();
  }

  notImplemented() {
    return Promise.reject('Placements.IO is read only, not implemented.');
  }

  forResources(): string[] {
    return ['accounts', 'campaigns', 'line_items', 'products', 'custom_fields'];
  }
}
