import {
  Create,
  Datagrid,
  Edit,
  List,
  TextField,
  TopToolbar,
  UrlField,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { ESTDateField } from './custom-ra-fields/est-date-field';
import { ClicksCreativeForm } from './forms/clicks-creatives';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ClickPreviewPositionMenuItem } from './buttons/click-preview-position-menu';
import { Menu, Button } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import { useTransformWithCampaignId } from './forms/hooks/useTransformWithCampaignId';

export const CreativesListActions = () => <TopToolbar />; // return empty tool bar to keep the spacing consistent

export const ClicksCreativesList = () => (
  <List actions={<CreativesListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="placementsLineItemId" />
      <TextField source="title" />
      <UrlField source="url" target="_blank" />
      <ESTDateField source="startsAt" />
      <ESTDateField source="endsAt" />
    </Datagrid>
  </List>
);

interface ClicksCreativeCreateInitialValues {
  placementsLineItemId: string;
}

export const ClicksCreativeCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();
  const placementsLineItemId = searchParams.get('placementsLineItemId') ?? '';
  const transform = useTransformWithCampaignId();

  const resource = useResourceContext();

  const record: ClicksCreativeCreateInitialValues = {
    placementsLineItemId,
  };

  const onSuccess = () => {
    notify('Saved new Clicks Creative');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Create record={record} mutationOptions={{ onSuccess }} redirect="list" transform={transform}>
      <ClicksCreativeForm type="Create" />
    </Create>
  );
};

export const ClicksCreativeEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const onSuccess = ({ placementsLineItemId }: { placementsLineItemId: number }) => {
    notify('Saved Clicks Creative');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }} redirect="list">
      <ClicksCreativeForm type="Update" />
    </Edit>
  );
};

/** A Button that when clicked, displays a menu of links that open a browser tab to preview the Click in a specified position*/
export const PreviewClickButton = () => {
  const record = useRecordContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const positionOptions = ['top', 'bottom', 'right', 'well'];

  return record?.id ? (
    <>
      <div>
        <Button
          onClick={handleClick}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          endIcon={<KeyboardArrowDownIcon />}
          size="small"
        >
          Preview
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {positionOptions.map(position => {
            return (
              <ClickPreviewPositionMenuItem
                key={position}
                position={position}
                record={record}
                setAnchorEl={setAnchorEl}
              />
            );
          })}
        </Menu>
      </div>
    </>
  ) : null;
};
