import {
  BooleanInput,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  required,
  RadioButtonGroupInput,
  FormDataConsumer,
  SelectInput,
  Loading,
  useGetOne,
} from 'react-admin';
import { Box } from '@mui/material';
import { useRecordContext } from 'react-admin';
import {
  CampaignLineItemsSelectInput,
  CurrentLineItemDateInputGroup,
  FormDivider,
  FormHeader,
  FormSectionHeader,
} from './form-components';
import { validateUrlFormat } from './form-utils';
import { CreativesFormProps } from './fma-creatives';
import { ClicksCreativeTargetingType } from 'src/generated/client';
import { titleize } from 'inflection';
import range from 'lodash/range';
import { CreativeToolbar, AutocompleteInput } from './shared';
import { PreviewClickButton } from '../clicks-creatives';

const targetingTypeChoices = (
  Object.keys(ClicksCreativeTargetingType) as Array<keyof typeof ClicksCreativeTargetingType>
).map(targetingTypeKey => {
  const targetingType = ClicksCreativeTargetingType[targetingTypeKey];

  return {
    id: targetingType,
    name: titleize(targetingType),
  };
});

export const ClicksCreativeForm = ({ type, ...rest }: CreativesFormProps) => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetOne('line_items', { id: record.placementsLineItemId });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return null;

  const lineItem = data;

  const defaultValues = {
    ...rest.defaultValues,
    startsAt: lineItem.startDate,
    endsAt: lineItem.endDate,
  };

  return (
    <SimpleForm
      {...rest}
      defaultValues={defaultValues}
      toolbar={<CreativeToolbar />}
      mode="onBlur"
      reValidateMode="onBlur"
    >
      <FormHeader>{`${type} Clicks Creative, Targeting & Pacing`}</FormHeader>

      <CampaignLineItemsSelectInput campaignId={lineItem.campaign.id} />

      <CreativeDetailsSection />
      <FormDivider />

      <TargetingDetailsSection />
      <FormDivider />

      <PacingSection />
    </SimpleForm>
  );
};

const CreativeDetailsSection = () => (
  <>
    <FormSectionHeader>Creative Details</FormSectionHeader>
    <BooleanInput source="isApproved" label="Approved" defaultValue={false} />
    <PreviewClickButton />
    <TextInput label="Flight / Segment Name" source="name" resettable fullWidth multiline validate={required()} />
    <CurrentLineItemDateInputGroup />
    <TextInput label="Creative Text" source="title" fullWidth resettable multiline validate={required()} />
    <TextInput label="Client URL" source="url" fullWidth resettable validate={[required(), validateUrlFormat]} />
    <TextInput label="Company / Brand Name" source="companyName" fullWidth resettable validate={required()} />
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextInput source="jobNumber" resettable sx={{ marginRight: '1rem' }} />
      <BooleanInput source="displayJobNumber" />
    </Box>
  </>
);

const TargetingDetailsSection = () => (
  <>
    <FormSectionHeader>Targeting Details</FormSectionHeader>

    <RadioButtonGroupInput source="targetingType" choices={targetingTypeChoices} />
    <FormDataConsumer>
      {({ formData }) =>
        formData.targetingType === ClicksCreativeTargetingType['TargetList'] ? (
          <AutocompleteInput resource="targetLists" source="targetListIdsList" validate={validateTargetList} />
        ) : (
          <>
            <AutocompleteInput resource="specialties" source="specialtyIdsList" validate={validateRunOfSpecialty} />
            <AutocompleteInput resource="professions" source="professionIdsList" />
          </>
        )
      }
    </FormDataConsumer>
  </>
);

const PacingSection = () => (
  <>
    <FormSectionHeader>Pacing</FormSectionHeader>
    <SelectInput
      source="priority"
      validate={[required()]}
      defaultValue={8}
      helperText="Between 1 (Highest) and 16 (Lowest)"
      choices={range(1, 17).map(value => {
        return {
          id: value,
          name: `${value}`,
        };
      })}
    />
    <CheckboxGroupInput
      source="hideOnDaysList"
      label="Hide on Days"
      choices={[
        { id: 'SUNDAY', name: 'Sunday' },
        { id: 'MONDAY', name: 'Monday' },
        { id: 'TUESDAY', name: 'Tuesday' },
        { id: 'WEDNESDAY', name: 'Wednesday' },
        { id: 'THURSDAY', name: 'Thursday' },
        { id: 'FRIDAY', name: 'Friday' },
        { id: 'SATURDAY', name: 'Saturday' },
      ]}
    />
  </>
);

const validateTargetList = (value: any, allValues: any) => {
  if (
    allValues.isApproved &&
    allValues.targetingType === ClicksCreativeTargetingType['TargetList'] &&
    value.length === 0
  ) {
    return 'If Target List targeting is selected, you must select at least one target list';
  }
  return undefined;
};

const validateRunOfSpecialty = (value: any, allValues: any) => {
  if (
    allValues.targetingType === ClicksCreativeTargetingType['RunOfSpecialty'] &&
    value.length === 0 &&
    allValues.professionIdsList.length === 0
  ) {
    return 'If Run Of Specialty targeting is selected, you must select at least one specialty or profession';
  }
  return undefined;
};
