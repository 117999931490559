import {
  Button,
  Create,
  Datagrid,
  Edit,
  List,
  TextField,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { CreativesListActions } from './clicks-creatives';
import { ESTDateField } from './custom-ra-fields/est-date-field';
import { FmaCreativeForm } from './forms/fma-creatives';
import LaunchIcon from '@mui/icons-material/Launch';
import { useSearchParams } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import { useTransformWithCampaignId } from './forms/hooks/useTransformWithCampaignId';

export const FmaCreativesList = () => (
  <List actions={<CreativesListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ESTDateField source="startsAt" />
      <ESTDateField source="endsAt" />
    </Datagrid>
  </List>
);

interface FmaCreativeCreateInitialValues {
  placementsLineItemId: string;
}

export const FmaCreativeCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();
  const placementsLineItemId = searchParams.get('placementsLineItemId') ?? '';
  const transform = useTransformWithCampaignId();

  const resource = useResourceContext();

  const record: FmaCreativeCreateInitialValues = {
    placementsLineItemId,
  };

  const onSuccess = () => {
    notify('Saved new FMA Creative');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Create record={record} mutationOptions={{ onSuccess }} redirect="list" transform={transform}>
      <FmaCreativeForm type="Create" />
    </Create>
  );
};

export const FmaCreativeEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const onSuccess = ({ placementsLineItemId }: { placementsLineItemId: number }) => {
    notify('Saved FMA Creative');
    redirect(`/line_items/${placementsLineItemId}/show`, resource);
  };

  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }} redirect="list">
      <FmaCreativeForm type="Update" />
    </Edit>
  );
};

/** A Button that when clicked, opens a browser tab to preview the FMA */
export const PreviewFMAButton = () => {
  const record = useRecordContext();

  return record?.id ? (
    <a href={`${process.env.NEXT_PUBLIC_MDLINX_BASE_URL}/?preview_fma=${record?.id}`} target="_blank" rel="noreferrer">
      <Button label="Preview" alignIcon="right" onClick={e => e.stopPropagation()}>
        <LaunchIcon />
      </Button>
    </a>
  ) : null;
};
